import React from "react"

/* Import Global Containers */
import Project from "~containers/project/project"

/* Import Global Components */
import Paragraph from "~components/paragraph/paragraph"
import InterviewAnswer from "~components/interviewAnswer/interviewAnswer"
import InterviewQuestion from "~components/interviewQuestion/interviewQuestion"

/* Import Local Styles */
import styles from "./julia-gardener.module.css"

const JuliaGardenerProject = () => (
  <Project
    title="PRES Interview – Magdalena Moskalewicz and Daniel Muzyczuk"
    author="Julia Gardener"
    className={styles.project}
  >
    <Paragraph>
      This interview with Magdalena Moskalewicz and Daniel Muzyczuk discusses
      the Polish Radio Experimental Studio, otherwise known as PRES. In her
      introduction to the Studio, Moskalewicz positions PRES as{" "}
      <i>
        “a site—understood equally as institution, physical space, and a circle
        of individuals.”
      </i>{" "}
      This exchange departs from this multidimensional approach to PRES, while
      emphasizing Moskalewicz’s and Muzyczuk’s work—as curators, art historians,
      and researchers—along with the Studio’s history and legacy.
    </Paragraph>
    <Paragraph>
      PRES was the first center for electronic music east of the Iron
      Curtain—and the seventh studio of its kind in the world—established to
      explore, produce, and disseminate new sonic possibilities. Founded in
      1957, the Studio continued to operate until 2004. Though its inception was
      predicated on producing requisite music for state radio broadcasts and
      film soundtracks, the Studio also made autonomous electronic pieces
      and independent compositions. While its most widely known outputs were
      undoubtedly film scores, {" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="http://warszawska-jesien.art.pl/en/2020/strona-glowna"
      >
        The Warsaw Autumn International Festival of Contemporary Music
      </a>
      , which continues to operate to this day, was a key way in which PRES’s
      independent material reached the public. The Studio was spearheaded
      by Józef Patkowski, with Krzysztof Szlifirski, Wojciech Makowski, Anna
      Skrzyńska, Eugeniusz Rudnik, and Bohdan Mazurek forming the original team.
      Later, the Studio would also draw in Gra
      <span style={{ fontFamily: "Sans-Serif" }}>ż</span>
      yna Karaśkiewicz, Barbara Okoń-Makowska, Ewa Guziołek-Tubelewicz, Tadeusz
      Sudnik, and Ryszard Szeremeta. It constituted a crucial center, attracting
      both local and international composers to work with its equipment and
      sound engineers. Some of these artists were Włodzimierz Kotoński, Andrzej
      Dobrowolski, Krzysztof Penderecki, Bogusław Schaeffer, El
      <span style={{ fontFamily: "Sans-Serif" }}>ż</span>bieta Sikora,
      Tomasz Sikorski, Krzysztof Knittel, Arne Nordheim, Dubravko Detoni,
      Franco Evangelisti, Roland Kayn, and Teresa Rampazzi.
    </Paragraph>
    <Paragraph>
      PRES operated out of what was known as the “Black Room.” A 6 x 6 meter
      studio was designed by the renowned Polish architects Oskar and Zofia
      Hansen. Tightly fitted with modular elements, innovative equipment,
      rotating frames, and movable panels, the room was both an audio tool and
      an audiovisual device. It was designed according to Oskar Hansen’s{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://artmuseum.pl/en/publikacje/oskar-hansen-opening-modernism-on-open-form-architecture"
      >
        Open Form theory
      </a>
      , which dictates that architectural space should be adaptable to its
      users. Crucially, the Black Room was located in the state-owned Polish
      Radio building in Warsaw.
    </Paragraph>
    <Paragraph>
      During the 50-odd years of the Studio’s operation, the sociopolitical
      climate in Poland went through critical shifts. On the one hand, for much
      of PRES’s history, Poland was still the Polish People’s Republic
      (PRL)—operating under Soviet influence, part of the Eastern Bloc, and
      ideologically separated from the West. On the other hand, PRES was formed
      at the very beginning of the Thaw which fortified civil rights and
      artistic freedom. The 60s and early 70s that followed are often understood
      as a particularly active period in Polish culture. In 1989 Poland
      transitioned from a communist to democratic state and in 2004 it joined
      the European Union.
    </Paragraph>
    <Paragraph>
      Since the Studio’s closure that same year, there has been a newfound wave
      of interest in their production. Notably, the products of this enthusiasm
      include:{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://post.moma.org/themes/14-polish-radio-experimental-studio-a-close-look"
      >
        post
      </a>
      , MoMA’s digital research platform with a dedicated section on PRES (with
      an extended list of sources here ); Sounding the Body Electric:
      Experiments in Art and Music in Eastern Europe 1957–1984, an exhibition
      held at{" "}
      <a target="_blank" rel="noreferrer" href="https://msl.org.pl/sounding/">
        Muzeum Sztuki
      </a>{" "}
      in in 2012 and in{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.calvertjournal.com/events/show/10927/sounding-the-body-electric"
      >
        Calvert 22
      </a>{" "}
      in London in 2013, plus the{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://msl.org.pl/sounding-the-body-electric--experiments-in-art-and-music-in-eastern-europe-1957-1984/"
      >
        accompanying catalogue
      </a>
      ; that released{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="http://www.boltrecords.pl/2,polish-radio,en.html"
      >
        an anthological series
      </a>{" "}
      of PRES music;{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://zkm.de/en/event/2018/07/opening-through-the-soundproof-curtain"
      >
        Through the Soundproof Curtain
      </a>
      , an exhibition held at ZKM | Karlsruhe in 2018; and subsequently{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.kehrerverlag.com/en/ultra-sounds-the-sonic-art-of-polish-radio-experimental-studio-978-3-86828-921-3"
      >
        Ultra Sounds: The Sonic Art of Polish Radio Experimental Studio
      </a>
      , a book released by ZKM | Karlsruhe in 2019. It is hoped that this
      interview will make a small contribution to this rich web of PRES
      resources.
    </Paragraph>
    <InterviewQuestion>
      What, to you, was PRES? Or rather, what of PRES are you most determined to
      research, preserve, or extend? Magdalena’s introduction notes that it can
      be “
      <i>
        understood equally as institution, physical space, and a circle of
        individuals
      </i>
      ,” so I am curious which facet of the Studio takes precedence for you and
      your work.
    </InterviewQuestion>
    <InterviewAnswer subject="Magdalena Moskalewicz (MM)">
      To me, PRES is fascinating specifically because it was all these three
      things simultaneously. Its institutional status cannot be fully detached
      from its physical location, complete with its design, and that location
      only makes sense as a place of use, a place of human interaction. Working
      on PRES made me realize that looking at history through the lens of the
      sites of artistic production—as opposed to, say, artists or
      artworks—grants to a historian an unparalleled perspective. While focusing
      on individual makers always carries with it a threat of the artist-genius
      rhetoric and looking at artworks alone may erase the social and political
      context from the horizon, sites—the places where art is made and
      circulated—are at the crossroads of individual agencies, spatial and
      aesthetic circumstances, material conditions, and socio-political
      dynamics.
    </InterviewAnswer>
    <InterviewAnswer subject="Daniel Muzyczuk (DM)">
      For me, it is the methodology of working with tape that allows me to think
      of other audiovisual reconfigurations in the art field as the structural
      outcomes of technological inventions. The materialization of sound in the
      form of tape strips is also something that opened doors for scientific
      research. This is the reason why spaces like PRES are always created in
      between the experimental laboratory and the artist’s studio. That factor
      also opened doors to the idea of concrete sounds as a reservoir that can
      be reshaped into universal language. The ambitions of these projects were
      really spectacular. However, most of the work is known simply through
      their finished pieces of music, which are listened to for aesthetic
      pleasure and contemplation. So, there is always this inconsistency between
      the horizon of intentions and where the actual, pragmatic work ends.
      However, the sounds ended up influencing people's lives and their ears in
      a totally different setting—the cinema and pop culture. Even if it is
      characteristic of most of the work of avant-gardes, I find this collision
      inspiring. The revolution was incited, but simply not in the area where it
      was intended.
    </InterviewAnswer>
    <InterviewQuestion>
      How did you come to work on PRES? What sparked your individual interests?
    </InterviewQuestion>
    <InterviewAnswer subject="MM">
      Three things came into play that led me to PRES. At the time, in 2012, I
      was working at The Museum of Modern Art conducting research into the
      <a
        target="_blank"
        rel="noreferrer"
        href="https://post.moma.org/by-the-way-whats-fluxus-jon-hendricks-on-the-formation-of-the-gilbert-and-lila-silverman-fluxus-collection/"
      >
        Gilbert and Lila Silverman Fluxus Collection
      </a>
      , and specifically focusing on Fluxus connections in Eastern Europe. I was
      also one of the founding editors of post , MoMA’s digital publication
      about to launch in early 2013, and together with my colleagues I was
      planning initial content on modern art from Latin America, East Asia, and
      Eastern Europe—it was my job to envision a subject for the latter. The
      first thing was that I had just completed a PhD that looked at
      experimental art in Poland between roughly 1955 and 1970, and so that was
      my main expertise. The second, that the Polish connection of George
      Maciunas in that early moment of Fluxus was Józef Patkowski—the two had
      met long before PRES or Fluxus ever existed—which led my research from
      visual arts into the milieu of experimental music. The third was that
      other content planned for post was thematizing{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://post.moma.org/content_items/154-a-very-brief-history-of-the-sogetsu-art-center"
      >
        Sogetsu Art Center
      </a>
      , a major hub for experimental music in Tokyo, which meant that the
      editorial team gave a lot of thought to the idea of <i>site</i> as a
      conveyor for artistic practice. These three elements: Fluxus, Poland, and
      focus on sites of artistic production came together to bring to my
      attention the Polish Radio Experimental Studio as the perfect case study.
    </InterviewAnswer>
    <InterviewAnswer subject="DM">
      There were two decisive moments when it comes to tracing the recent
      research on the Studio work. The initial impulse came from two curators
      involved with the music scene: Michał Mendyk and Michał Libera, who
      started uncovering the archives of PRES in an unorthodox manner. Since
      access to the musical material made at the institute was restricted, they
      used publicly available scores to reformulate the questions posed by music
      on tape. Through their projects they were trying to reimagine the music
      through commissioning new interpretations of these materials. The scores
      that were intended to be used as a source for consultation—and were
      sometimes even created only after the piece of music was completed—started
      to be treated as an invitation to be shaped anew. This reconstructive look
      toward the future was truly inspiring. Later Mendyk started a whole series
      of anthologies at{" "}
      <a target="_blank" rel="noreferrer" href="http://boltrecords.pl">
        B<span style={{ fontFamily: "Sans-Serif" }}>ô</span>łt Records
      </a>
      , dedicated to uncovering different aspects of music produced at PRES.
      This should be seen as a logical consequence of the initial unorthodox and
      creative moment. The other trigger was the fact that David Crowley had an
      idea to organize an exhibition [
      <i>
        Sounding the Body Electric: Experiments in Art and Music in Eastern
        Europe 1957– 1984
      </i>
      ] around the theme of the intersection of art and new music in socialist
      countries after 1956. He approached Muzeum Sztuki and I joined him in this
      journey. The research was focused on the involvement of PRES with cinema
      and visual arts, so it formed a natural alliance with B
      <span style={{ fontFamily: "Sans-Serif" }}>ô</span>łt Records. The
      research was used for two iterations of the exhibition, which took place
      at Muzeum Sztuki in <span style={{ fontFamily: "Sans-Serif" }}>Łódź</span>{" "}
      in 2012 and Calvert 22 in London in 2013. On a more personal level, as a
      curator interested in the influence of new technologies on art of the 60s
      and 70s, PRES is a natural treasure trove. It entered socialist pop
      culture through cinema, so even if you had never heard of the composers
      that were working there, you would probably have known their music. But
      this just scratches the surface of a lot of cutting-edge research and
      projects.
    </InterviewAnswer>
    <InterviewQuestion>
      I am interested in the myriad of ways you have approached the subject. In
      your work on PRES, you have dealt with original audio, video recordings,
      score reprints, and documentary photographs through a digital publishing
      platform, as well as books, interviews, commissioned essays, exhibitions,
      and an ongoing bibliography. What has your experience been with making
      these available through such a wide range of platforms?
    </InterviewQuestion>
    <InterviewAnswer subject="MM">
      Working as an editor of a digital publication alongside art curators, who
      have at their disposal magnificent physical galleries, while you only have
      the vastness of the immaterial online space, you think about projects that
      would complement other museum programing rather than trying to compete
      with it. Sound pieces are actually very challenging to feature in a
      gallery space, as are multipage rare books (in this case: sheet music).
      Post provided the perfect space to spotlight the multifaceted character of
      the Experimental Studio while PRES turned out to be the ideal subject to
      highlight the full functionality of the platform. On the combination of
      formats—essays, interviews, archival materials, multimedia—this variety is
      a feature of the platform in general, as it had been conceived as a hybrid
      between an online magazine, a digital archive, and a project space.
      Looking at PRES as a site—rather than a group of people—allowed us to
      include a variety of materials without imposing an artistic hierarchy. I
      have seen people calling the PRES material on <i>post</i> an “online
      exhibition,” and I guess that is not incorrect, though I do think that
      doesn’t account for the hybridity of the format.
    </InterviewAnswer>
    <InterviewQuestion>
      And what about bringing PRES specifically into an art context?
    </InterviewQuestion>
    <InterviewAnswer subject="DM">
      Works of art are naturally the primary material for an exhibition. By this
      I mean pieces that were originally intended to be presented in a gallery
      or a museum, sometimes even made by a visual artist. Closer to the gallery
      world are film stills, even if they were originally circulated in the
      cinema context. Of course, the interest of a curator representing a
      specific institutional context changes the classification of a particular
      object. Graphic scores are in this group. Even if some of the authors were
      connected with the gallery world, like Bogusław Schaeffer, others were
      producing these forms of notation as a method of mediation between
      composer and performer (or, in the case of the sound engineer, music on
      tape). However, the visual qualities of these scores, which appeal
      directly to those familiar with the whole tradition of conceptual art,
      change their status into an object of aesthetic contemplation. We also
      needed to consider reconstructions of key intermedia works that were not
      preserved or well documented. The methodology offered by the graphic score
      proved to be adequate. Hence, for the Sounding the Body Electric show, we
      were able to document{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="http://www.boltrecords.pl/2,polish-radio/12,sounding-the-body-electric,pl.html"
      >
        a new performance of a piece
      </a>{" "}
      written by a visual artist Krzysztof Wodiczko and composer Szabolcs
      Esztényi. The same principle was used to build the reconstruction of the
      first sound installation in Poland—
      <a
        target="_blank"
        rel="noreferrer"
        href="http://boltrecords.pl/5,polish-oldschool/27,zygmunt-krauze-spatial-music,pl.html"
      >
        Spatial-Musical Composition
      </a>{" "}
      by Zygmunt Krauze, Teresa Kelm, and Henryk Morel in 1968. The work of this
      team—consisting of a composer, an architect, and a sculptor—was also
      treated as if it was a score, which allowed us to construct another
      performance.
    </InterviewAnswer>
    <InterviewQuestion>
      In an effort to build on the history of PRES, where do you position
      musical (re)interpretations? For example, I am thinking here about the
      establishment of the{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.ableton.com/en/blog/sounds-polish-radio-experimental-studio/"
      >
        PRES sound library
      </a>{" "}
      in 2018 and the subsequent music competition in 2019, which asked
      participants to use samples from the library to create new compositions.
      Is this what it means to extend the history of PRES? To continue its ethos
      of recycling sound? To make its sound contemporary?
    </InterviewQuestion>
    <InterviewAnswer subject="DM">
      This is a direct continuation of the efforts of Mendyk that I mentioned
      earlier. Both Libera and Mendyk saw their curatorial work with the PRES
      archives not only as archival, but also as intermediary—those that create
      the context for past works to feel relevant. There is one more level to
      this activity. Unlike{" "}
      <a target="_blank" rel="noreferrer" href="https://inagrm.com/fr">
        GRM
      </a>{" "}
      in Paris or{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="http://elektronmusikstudion.se/"
      >
        EMS
      </a>{" "}
      in Stockholm, PRES was closed. These other studios still welcome
      composers, who are allowed to use their equipment and archives. The works
      from the 60s have a natural continuation in the pieces by younger
      generations. The opening of the sound library is an attempt at a similar
      effect—it is not simply about the culture of remix, but more about the
      continuity.
    </InterviewAnswer>
    <InterviewQuestion>
      What did it mean for PRES to be situated within the building of the Polish
      Radio, a national entity that continues to operate under governmental
      rule?
    </InterviewQuestion>
    <InterviewAnswer subject="MM">
      PRES was not only situated in a state-owned building as a part of a state
      institution. It could only exist and function specifically because it was
      funded and protected by the state. It is one of the paradoxes that is
      difficult to understand particularly from the US American perspective,
      where state funding for culture is automatically equated with state
      censorship and control. The realities of the Polish People's Republic,
      with its cultural politics and administration, were rather complex. In
      this case, it was enough to receive the support of one influential
      individual, Włodzimierz Sokorski, who was the Minister of Culture and Art
      between 1952 and 1956, to automatically get a free hand in
      experimentation.
    </InterviewAnswer>
    <InterviewAnswer subject="DM">
      The spatial arrangement of the studio and its location were of course due
      to the state of technology. Naturally, with the cheaper production of
      instruments and tools, access became more democratic. Also, this
      location's proximity to power was not a coincidence. There was a very
      curious mixture of elements that resulted in the operations of PRES. A
      politically charged location where composers and artists were working
      strictly on invitation from the Studio itself. The tools were available,
      but only for a limited number of people belonging to the club. The
      atmosphere was built on the elitist character of new music, as well as a
      bit of hermetism founded on the fact that the specific tools and methods
      were rather hard to understand. The state also needed that element in
      order to appear progressive.
    </InterviewAnswer>
    <InterviewQuestion>
      Following from this, Daniel, I wanted to open the next question with a
      reference to an incredible segment from{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://post.moma.org/how-much-rudnik-is-in-penderecki-and-how-much-rudnik-is-in-nordheim-interview-with-eugeniusz-rudnik/"
      >
        an interview
      </a>{" "}
      you did with Eugeniusz Rudnik, where he said:
    </InterviewQuestion>
    <InterviewQuestion>
      <i>
        Did the creation of art ennoble that empty shell of a state? Should I be
        proud of that? Or should I be ashamed that I legitimized it to the
        world, with my own hands, as the producer of these works? To the first
        question, I answer, “Yes, of course.” . . . [It] meant that Poland was
        alive. . . . Was it worth making art? Yes, it was worth it, and it was
        needed. Fortunately, we created works that lasted, that successfully
        function today in the free world, in free Poland.
      </i>
    </InterviewQuestion>
    <InterviewQuestion>
      Especially from today’s perspective, there seems to be contradictions
      about living and working under communism. Could you speak to the political
      climate of Poland during PRES’s epoch and how it shaped the Studio’s
      possibilities?
    </InterviewQuestion>
    <InterviewAnswer subject="DM">
      The Studio was an instrument that allowed the authorities to present the
      Polish People's Republic as a progressive state. PRES was well known in
      the new music world and there was some public exposure through its role in
      the creation of film soundtracks. But it was mostly recognized as a space
      for autonomous experimentation. In that sense it was politically safe, as
      this kind of music did not have to be heavily controlled because it never
      had any direct political message (except for times when it was used for
      direct propaganda). However, when you look closely at individual works,
      some of them do stand out as political statements.
    </InterviewAnswer>
    <InterviewAnswer>
      Agnieszka Pindera recently published{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://agapindera.com/filter/publication/Patkowski-Ambasador-muzyki-z-Marsa"
      >
        a biography of Patkowski
      </a>
      —the first director of PRES. It gives a good idea of the politics of new
      music and the role of the employees of Polish Radio. Patkowski, as the
      head of the Polish Composers’ Union, decided that during martial law the
      Warsaw Autumn Festival would not take place. This was probably one of the
      decisions that led to him losing his position at the Studio. PRES took
      part in the discussions on the passive role of the viewer/listener of new
      music and on the process of emancipation of the performer. This was not
      only seen in music. A similar movement of visual artists connected with
      the{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://monoskop.org/Groupe_de_Recherche_d%27Art_Visuel"
      >
        GRAV collective
      </a>{" "}
      was associated with the restructuring of public space and politics around
      May 1968. Thus, all the projects connected with the emancipation of
      listener and performer should be seen in a political horizon—even if, for
      the authorities, they were solely reserved for internal discussions. Then
      there was a group of works that were also posing more direct political
      questions. This includes the works of Krzysztof Wodiczko, an industrial
      designer who entered the field of audiovisual art with questions on
      autonomy of art and its efficiency in real life. His{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://zasoby.msl.org.pl/arts/view/110"
      >
        Personal Instrument
      </a>{" "}
      is exactly the kind of project that allows us to see how sound can be
      political. He created a set of gloves with photo sensors and microphones
      that controlled the sounds delivered to the user via headset. So the piece
      allowed one to control the sonosphere of a strictly controlled public
      sphere. Some of Krzysztof Knittel’s compositions from the 70s also touch
      on the possibility of creating spheres of autonomy for the artist, along
      with the social and political limits of these spaces. Finally, there was a
      group that was directly and socially involved. For the{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="http://greatcomposers.nifc.pl/en/nordheim/catalogs/places/373_osaka-congress-hall-expo-70"
      >
        Scandinavian Pavilion at the Expo in Osaka
      </a>{" "}
      in 1970, Arne Nordheim, with assistance from Rudnik, created a piece that
      used a live mix of audio recordings indicating ecological struggles. Art
      was used to direct the gaze of the listener towards these issues.
    </InterviewAnswer>
    <InterviewAnswer subject="MM">
      With that question, you are pointing to one of the biggest dilemmas that
      art historians of the period are dealing with today. Is the official art
      made between 1945 and 1989 automatically Communist? Should art that
      originated as a state commission be dismissed en masse? Or should its
      aesthetic form and/or its later functioning be allowed to define its
      ultimate value on a case by case basis? The one way that I can answer this
      question very concisely is to say that I don’t believe that “freedom” and
      “lack of freedom/dependence” are categories that are helpful in
      understanding art produced under Communism. These are the
      categories—together with “original/derivative”—that have been often used
      by academics in relation to this material, but I find them rather
      limiting.
    </InterviewAnswer>
    <InterviewQuestion>
      I wanted to mention a project called{" "}
      <a target="_blank" rel="noreferrer" href="https://citiesandmemory.com/">
        Cities and Memory
      </a>
      , which is a crowdsourced site for pooling together field recordings from
      across the world. Recently, it has charted how our aural lives are
      changing under the pandemic lockdown—it brings together the sound of a
      near-silent Times Square, the clatter of the NHS clap in London, the first
      time a man living in central Warsaw can hear birdsong. This made me think
      of how PRES focused on sonically “<i>rediscovering the mundane</i>
      ”—concrete noises, discarded sounds, etc. Do you think the PRES sound
      library gives us something temporally and geographically specific?
    </InterviewQuestion>
    <InterviewAnswer subject="DM">
      There is a set of common denominators in early electroacoustic and
      electronic music that make it into the universal language that Pierre
      Schaeffer was after. But of course, there are personal traits in the work
      of individual composers. What makes PRES special is that there were
      engineers on permanent contracts that were working with composers to
      realize their ideas. And since some of them, like Rudnik or Bohdan
      Mazurek, were also composers themselves, it led to working with a certain
      set of sounds or ways of doing things. You can sometimes hear the same
      sounds used by Rudnik in his own work and in the work of Nordheim. But
      there is also another community of sounds: a shared library. Some of the
      sound objects were shared between different users. This was the case with
      the collection of percussion sounds that was commissioned by Michael
      Ranta. The engineers were also exchanging sounds with each other. One also
      needs to add another factor to the equation—the fact that everyone is
      looking for innovation. Hence, as always with modern art, we have this
      delicate balance between familiarity and novelty.
    </InterviewAnswer>
    <InterviewQuestion>
      The Studio has been{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.kehrerverlag.com/en/ultra-sounds-the-sonic-art-of-polish-radio-experimental-studio-978-3-86828-921-3"
      >
        described as
      </a>{" "}
      “<i>international connection</i>.” Especially in its earlier years, this
      was a central part of its significance and success—the ability to host and
      collaborate with composers from abroad. When you were charting the history
      of the Studio, how much of the narrative felt distinctly local and how
      much felt principally international?
    </InterviewQuestion>
    <InterviewAnswer subject="DM">
      The Studio drew attention to itself from the start, as many Western
      composers were looking for opportunities to work behind the Iron Curtain.
      Some were politically oriented towards the left, others merely curious.
      The Studio became a meeting hub, just like the Warsaw Autumn Festival. The
      state was also investing in infrastructure and promotion in those early
      years. In the late 60s PRES was also accepting Fulbright scholars. As the
      years passed, the state radio became less interested in preserving
      international connections.
    </InterviewAnswer>
    <InterviewQuestion>
      How important was PRES as a physical and shared space? And would it be any
      different if it were still operating today? I am thinking about how
      digital and cyber technologies were a significant factor in the downfall
      of the Studio and wondering if you would argue that cyberspace or bedroom
      studios could become the sole site for creating and fostering artistic
      experimentation, or if it remains important to maintain physical, shared
      spaces. In the context of PRES, this applies to music production
      specifically, but it remains a relevant—and, indeed, timely—question for
      all cultural practices.
    </InterviewQuestion>
    <InterviewAnswer subject="MM">
      Bedroom studios have my full support, especially under the current
      circumstances of a global lockdown. But there is something to be said
      about the power of a shared space—not just a physical room, but also a
      legal entity with all the institutional implications (the good and the
      bad); a more conceptual, imagined nod that creates a sense of belonging
      and allows for creative solidarity, not to mention fostering intellectual
      and artistic exchanges. The digital space gave birth to unprecedented new
      models of art making. But it cannot serve as a full replacement for being
      together, as I think we are all becoming painfully aware right now . . .
    </InterviewAnswer>
    <InterviewQuestion>
      What about the Black Room, PRES’s studio space designed by the famous
      Polish architects, Oskar and Zofia Hansen? Józef Patkowski’s dedication to
      creating a special space for electronic music has been described as an “
      <i>unheard-of phenomenon</i>,” lauded for looking to pair function with
      impressive architectural design. Since then, it’s been heavily
      mythologized,{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://culture.pl/en/work/ultra-sounds-the-sonic-art-of-polish-radio-experimental-studio-david-crowley-ed"
      >
        described
      </a>{" "}
      as “<i>an instrument</i>” and “<i>sound laboratory</i>.” In reality, how
      much of that was the space itself? Has it been lost forever? Does it
      matter? I’ve read that Warsaw’s Museum of Modern Art{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://culture.pl/en/article/who-is-eugeniusz-rudnik"
      >
        had the intention of rebuilding it
      </a>
      , but I haven’t been able to confirm what’s happened since.
    </InterviewQuestion>
    <InterviewAnswer subject="DM">
      The Museum of Modern Art in Warsaw has indeed announced that the new
      building of the institution, which is currently being erected, will
      feature a reconstruction of the Black Room’s interior. The museum was
      involved with the estate of Oskar Hansen, so it’s a logical move. However,
      the interior was highly criticized by Rudnik for a number of reasons. The
      blinds that were meant to allow the composer to change the sonic qualities
      of the room were really hard to move. Moreover, their presence was due to
      the fact that initially the studio was also meant to be a space for
      microphone recordings. However, they were usually done elsewhere, so
      nobody really needed more reflective or more absorbing surfaces. The other
      problem was the miniaturization of equipment that soon led to it not
      fitting into the custom equipment racks. This is connected with the main
      problem—that the studio was simply too small and that one needs space in
      order to have infrastructure that is flexible and can be adjusted to the
      needs of the user.
    </InterviewAnswer>
    <InterviewAnswer subject="MM">
      Hansen’s was a cutting-edge functionalist design that did not exactly
      serve its function. Not an unseen situation in the history of modernism.
      Still, utterly fascinating. As to the reconstruction, I am generally
      somewhat suspicious of all the reconstructive efforts, so common in the
      art world these days, especially when it comes to recreating art and
      exhibitions from the 1960s. Some of the art that’s being reconstructed was
      never meant to last. Exhibitions are, by definition, short-lived. I
      understand the pedagogical impulse and even identity with it. But
      sometimes the past should be allowed to stay in the past. Will we
      understand the studio better by rebuilding it and putting it inside an art
      museum?
    </InterviewAnswer>
    <InterviewQuestion>
      Magdalena, I wanted to turn to{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://post.moma.org/magnetic-tape-as-instrument-a-rare-selection-of-electroacoustic-music-from-poland/"
      >
        your essay
      </a>{" "}
      on magnetic tape as an instrument. In it, you mention the first
      electroacoustic concert in Poland, which took place in 1960—an event that
      was part of the Warsaw Autumn Festival, which had notable overlaps with
      the Studio. It was the first occasion for playing Włodzimierz Kotoński’s{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="http://warszawska-jesien.art.pl/en/2020/strona-glowna"
      >
        Study for One Cymbal Stroke
      </a>
      , one of the first and most famous compositions to come out of PRES. It’s
      a unique and notable concert, because, save for loudspeakers, the stage
      was virtually empty. What do you think liveness meant for PRES? And
      gathering?
    </InterviewQuestion>
    <InterviewAnswer subject="MM">
      The fact that Kotoński’s Study even debuted at Warsaw Autumn Festival,
      with loudspeakers placed on the stage, shows commitment to the established
      ways of experiencing music—as well as the commitment to the shared aspect
      of musical experience, since Warsaw Autumn was a major international
      event. This concert took place at a high point of Polish modernism
      (nowoczesno<span style={{ fontFamily: "Sans-Serif" }}>ść</span>), about
      five years after the political and cultural Thaw allowed any artistic
      experimentation on a larger scale after Stalinism. Nineteen sixty was a
      moment when people were still genuinely excited about the possibilities
      granted by new technologies; a time when such technologies were seen as a
      tool for emancipation. And so the elimination of live performers was not
      conceived as a threat to live music—such dystopian views only enter much
      later—but a welcome and necessary experimentation.
    </InterviewAnswer>
    <InterviewQuestion>
      I have a twofold question about PRES’s audience. You’ve both{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.thefirstnews.com/article/fascinating-exhibition-tells-the-story-of-polands-avant-garde-music-scene-1261"
      >
        emphasized
      </a>{" "}
      that during its time of operation PRES was a “<i>hermetic place</i>,” a “
      <i>niche</i>” that was principally of interest to musical experts,
      connoisseurs, and specialists. Composers and artists would come to PRES
      specifically to access this expertise—a notable example is Krzysztof
      Penderecki’s long-lasting collaboration with Eugeniusz Rudnik. This
      translated into a large share of their autonomous work not being familiar
      to a wider public—aside from the radio, most people didn’t have musical
      equipment at home and the Studio rarely shared musical scores. Rudnik
      cites special but poorly attended concerts in the basement of the National
      Philharmonic. But of course, its dual role as a musical and commercial
      entity meant that some of PRES’s work was widely heard—I am thinking
      specifically of its non-autonomous work for television and films. With
      this in mind, I am curious about the contemporary interest in the Studio.
      Would you say this is still a niche subject, pursued most actively by
      those with a pointed interest in early electronic music of the Eastern
      Bloc? And, thinking more specifically about the reception of your
      research, were you met with an equally specialist audience?
    </InterviewQuestion>
    <InterviewAnswer subject="MM">
      You could probably call it a niche, though it is one that cuts through
      disciplines (music/art/new media) and attracts various audiences: from
      music amateurs on one end and scholars of Eastern Bloc on the other end of
      the spectrum. And some of the music that came out of the Studio—think
      Krzysztof Penderecki, for example—went right into the international
      mainstream of contemporary classical music. As it comes to the reception
      of this material in New York City, the people most enthusiastic were,
      indeed, scholars and amateurs of experimental and contemporary music, plus
      musicians and sound artists. But it's a pretty big crowd there that one
      can hardly qualify as a “niche.”
    </InterviewAnswer>
    <InterviewAnswer subject="DM">
      On the one hand, the pieces produced in the studio are still listened to
      by a rather closed circuit of people. On the other hand, the activity of
      different researchers and curators opened doors for an audience that was
      previously not aware of the existence of PRES or its role as a laboratory
      for audiovisual arts. The show I curated with Mendyk at ZKM in Karlsruhe
      [Through the Soundproof Curtain] and the accompanying publication [Ultra
      Sounds: The Sonic Art of Polish Radio Experimental Studio] expanded its
      recognition. Another element was the sample library mentioned previously
      and the commissions of new music using the Studio’s soundbank. This is
      expanding the audience of people that appreciate Rudnik, because Matmos
      referenced his work in their music.
    </InterviewAnswer>
    <InterviewQuestion>
      To close, I wanted to turn to{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://post.moma.org/content_items/400-polish-radio-experimental-studio-a-galaxy-of-writings-prints-and-sound"
      >
        Bolesław Błaszczyk’s words
      </a>
      , which read—“
      <i>
        Rescued from near-oblivion, PRES is finally becoming recognized for its
        important contributions to European music and art. The galaxy proposed
        below, just like the celestial galaxies, is still extending. There are
        still more stars to discover
      </i>
      .” Why do you think PRES stayed in the shadows for so long? And what do
      you think has to happen so that it never recedes back into them again?
    </InterviewQuestion>
    <InterviewAnswer subject="DM">
      The situation of other similar venues is not so different. The renaissance
      of interest in the{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.bbc.co.uk/music/artists/39f0d457-37ba-43b9-b0a9-05214bae5d97"
      >
        BBC Radiophonic Workshop
      </a>{" "}
      or GRM is fairly recent. Starting somewhere in the mid 2000s, revisiting
      the heritage of both of these was easier with more records and releases
      and less significant issues related to language barriers. Since then a lot
      of the work by B<span style={{ fontFamily: "Sans-Serif" }}>ô</span>łt
      Records, Muzeum Sztuki in{" "}
      <span style={{ fontFamily: "Sans-Serif" }}>Łódź</span>, or{" "}
      <a target="_blank" rel="noreferrer" href="https://iam.pl/en">
        Adam Mickiewicz Institute
      </a>{" "}
      has been invested in archiving, preserving, recontextualizing, and
      translating. At Muzeum Sztuki we are also collecting works that are
      connected with the Studio and{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://zasoby.msl.org.pl/arts/show/0/23?sort_type=1"
      >
        putting them online
      </a>
      , creating a new space for the variety of this production. It simply takes
      a bit longer for foreign researchers to get involved with this material.
    </InterviewAnswer>
    <InterviewAnswer subject="MM">
      I sympathize with Bolek Błaszyczyk’s sentiment. The truth is, though, that
      this rediscovery of PRES is very much in line with the more general
      interest in art of the period—the 1950s and 1960s—in recent art historical
      scholarship. And, more globally, with the interest in art practices that
      emerged in the former Eastern Europe under Communism. Even the basis for
      our collaboration, Daniel’s and mine, was a museum endeavor that speaks to
      that growing interest: a global research program at MoMA, called{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.moma.org/research-and-learning/international-program/global-research"
      >
        C-MAP
      </a>
      , that the Museum started in 2010. And the amount of scholarship about
      PRES that has been produced in the past years in Poland and abroad assures
      the Studio’s history and cultural contributions are now universally
      recognized.
    </InterviewAnswer>
    <Paragraph>
      Magdalena Moskalewicz, PhD, is an art historian, curator, and editor;
      Assistant Professor, Adjunct, at the School of the Art Institute of
      Chicago. Moskalewicz specializes in art of the 1950s, 60s, and 70s in the
      former Eastern Europe: she was awarded a PhD in art history for her
      research into the experiments with painting in Poland in the aftermath of
      the post-Stalinist Thaw and has published internationally on postwar
      abstraction; Eastern European neo-avant-gardes, including their
      connections to Fluxus; and on the international circulation of modern art
      during the Cold War—as well as contemporary practices. In 2012–2015,
      Moskalewicz was Andrew W. Mellon Postdoctoral Fellow at The Museum of
      Modern Art in New York, where she conducted research and organized
      academic programs for the Eastern European branch of MoMA’s global
      program, C-MAP, and served as co-editor of the Museum’s digital publishing
      platform, <i>post</i>. Her curatorial projects engage in collaborations
      with living artists, examining the postsocialist condition and its
      parallels with postcoloniality. Among others, Moskalewicz curated
      Halka/Haiti 18°48’05′′N 72°23’01′′W for the Polish Pavilion at the 56th
      Venice Biennale (2015) and The Travellers: Voyage and Migration in New Art
      from Central and Eastern Europe (2016–2018), both accompanied by books.
      Recent scholarly writing includes contributions to The Oxford Handbook of
      Communist Visual Cultures (Oxford University Press, 2020) and
      Reconstructions, Restagings, Re- enactments: Exhibition Histories in the
      Twenty-First Century (Routledge, forthcoming).
    </Paragraph>
    <Paragraph>
      Daniel Muzyczuk is Head of the Modern Art Department at Muzeum Sztuki in
      <span style={{ fontFamily: "Sans-Serif" }}>Łódź</span>, Poland. Curator of
      the exhibitions Gone to Croatan (with Robert Rumas), CoCA Toruń / HMKV,
      Dortmund; Sounding the Body Electric: Experiments in Art and Music in
      Eastern Europe 1957–1984 (with David Crowley), Muzeum Sztuki,{" "}
      <span style={{ fontFamily: "Sans-Serif" }}>Łódź</span> / Calvert 22,
      London; Notes from the Underground: Art and Alternative Music in Eastern
      Europe 1968–1994 (with David Crowley), Muzeum Sztuki,{" "}
      <span style={{ fontFamily: "Sans-Serif" }}>Łódź</span> / Akademie der
      Künste, Berlin; The Museum of Rhythm (with Natasha Ginwala), Muzeum
      Sztuki, <span style={{ fontFamily: "Sans-Serif" }}>Łódź</span>; Through
      the Soundproof Curtain: The Polish Radio Experimental Studio (with Michał
      Mendyk), ZKM, Karlsruhe; among others. Co-curator of the Polish Pavilion
      at the 55th Venice Biennale (with Agnieszka Pindera). Published texts in
      The Exhibitionist, NERO, Mousse Magazine, and e-flux journal.
      Vice-president of the International Association of Art Critics (AICA),
      Poland. Recipient of Garage Museum of Contemporary Art Field Research
      scholarship. Participated in the MoMA International Curatorial Institute,
      New York, 2015. Member of Grupa Budapeszt.
    </Paragraph>
  </Project>
)

export default JuliaGardenerProject
